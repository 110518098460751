html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: S-Core Dream;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  background: none;
  font-family: inherit;
  cursor: pointer;
  border: none;
}

#root>div:first-of-type {
  position: relative;
}

/* 카카오 지도 필요없는 부분 제거 */
.wrap_controllers.hide {
  display: none;
}

.wrap_btn_zoom {
  display: none !important;
}

@font-face {
  font-family: "Saira Condensed";
  font-weight: 200;
  src: url(../assets/fonts/SairaCondensed-ExtraLight.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Saira Condensed";
  font-weight: 300;
  src: url(../assets/fonts/SairaCondensed-Light.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Saira Condensed";
  font-weight: 400;
  src: url(../assets/fonts/SairaCondensed-Regular.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Saira Condensed";
  font-weight: 500;
  src: url(../assets/fonts/SairaCondensed-Medium.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Saira Condensed";
  font-weight: 600;
  src: url(../assets/fonts/SairaCondensed-SemiBold.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Saira Condensed";
  font-weight: 700;
  src: url(../assets/fonts/SairaCondensed-Bold.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "S-Core Dream";
  font-weight: 100;
  src: url(../assets/fonts/SCDream1.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "S-Core Dream";
  font-weight: 200;
  src: url(../assets/fonts/SCDream2.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "S-Core Dream";
  font-weight: 300;
  src: url(../assets/fonts/SCDream3.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "S-Core Dream";
  font-weight: 400;
  src: url(../assets/fonts/SCDream4.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "S-Core Dream";
  font-weight: 500;
  src: url(../assets/fonts/SCDream5.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "S-Core Dream";
  font-weight: 600;
  src: url(../assets/fonts/SCDream6.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "S-Core Dream";
  font-weight: 700;
  src: url(../assets/fonts/SCDream7.woff) format("woff");
  font-display: swap;
}