.body {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -70px;
  background-image: url("../assets/images/welcome.png");
  background-size: cover;
  main.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: #ffffff;
    border: solid 1px #e0e0e0;
    padding: 101px;
    padding-bottom: 27px;
    border-radius: 33px;
    box-shadow: 0 5px 5px 0 rgba(128, 128, 128, 0.75);
    .logo {
      width: 217.3px;
    }
    .title {
      margin-top: 76px;
      font-size: 22px;
      color: #404040;
      font-weight: bold;
      span {
        color: #ef857d;
      }
    }
    .wrapper {
      margin-top: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 21px 60px;
      background-color: #f4f4f1;
      font-size: 17px;
      text-align: center;
      color: #808080;
      margin-bottom: 41px;
      letter-spacing: -1.65px;
      line-height: 1.54;
    }
    .btn-download {
      background-image: url("../assets/images/welcome/button.png");
      background-color: transparent;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 255.1px;
      height: 80.2px;
      background-size: cover;
      letter-spacing: -1.4px;
      span {
        margin-top: -10px;
      }
    }
    .copyright {
      font-size: 12px;
      color: #b3b3b3;
      margin-top: 86px;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: -50px;
    main.main {
      padding: 30px;
      width: 80%;
      .logo {
        margin-top: 50px;
        width: 160px;
      }
      .title {
        font-size: 20px;
        margin-top: 50px;
      }
      .wrapper {
        font-size: 14px;
        padding: 15px 30px;
      }
      .btn-download {
        span {
          margin-top: -12px;
          font-size: 16px;
        }
      }
      .copyright {
        margin-top: 66px;
      }
    }
  }
}
