.body {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -70px;
  background-color: #ededed;
  main.main {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 33px;
    overflow: hidden;
    width: 552px;
    .head {
      width: 100%;
      height: 137px;
      background-image: url("../assets/images/welcome/top.png");
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
    }
    .form-wrapper {
      padding: 76px 97px;
      padding-bottom: 93px;
      text-align: center;
      .form-title {
        margin-bottom: 77px;
        font-size: 33px;
        font-weight: bold;
        text-align: center;
        color: #404040;
      }
      form {
        margin: 0;
        .input-wrapper {
          text-align: left;
          .input-title {
            font-size: 16px;
            color: #4a4a4a;
            margin-bottom: 5px;
          }
          input {
            width: 100%;
            height: 33px;
            border: none;
            border-bottom: solid 2px #ededed;
            padding: 0px;
            font-size: 18px;
            color: #808080;
            font-weight: bold;
          }
          & + .input-wrapper {
            margin-top: 40px;
          }
        }
        .btn-submit {
          width: 255.1px;
          height: 80.2px;
          background-image: url("../assets/images/welcome/signup-button.png");

          color: #ffffff;
          margin: 0 auto;
          margin-top: 37px;
          background-color: transparent;
          background-position: center;
          background-size: cover;
          span {
            font-size: 18px;
            margin-top: -13px;
            font-weight: bold;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: -50px;
    main.main {
      width: 80%;
      .form-wrapper {
        margin-bottom: 50px;
        padding: 45px 25px;
        .form-title {
          margin-bottom: 50px;
          font-size: 20px;
        }
        form {
          .input-wrapper {
            input {
              width: 100%;
              font-size: 16px;
            }
          }
          .btn-submit {
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            span {
              font-size: 16px;
              margin-top: -13px;
            }
          }
        }
      }
    }
  }
}
